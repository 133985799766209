<!--
 * @Description: 售前售后
 * @Autor: JINbiao
 * @Date: 2021-06-10 16:00:34
 * @LastEditors: Please set LastEditors
 * @LastEditTime: 2023-03-02 21:04:36
-->
<template>
  <SideBar :groups="groups"></SideBar>
</template>

<script>
import SideBar from '@/components/SideBar'
export default {
  components: {
    SideBar
  },

  data() {
    return {
      groups: [
        {
          label: '视频教程',
          list: [
            {
              name: '视频分类',
              path: '/mall/video/video-category'
            },
            {
              name: '视频专题',
              path: '/mall/video/video-topic'
            },
            {
              name: '培训视频',
              path: '/mall/video/video-article'
            }
          ]
        },
        {
          label: '培训权限',
          list: [
            {
              name: '视频密码',
              path: '/mall/video/video-password'
            }
          ]
        }
      ]
    }
  }
}
</script>
